import React from "react";
import { useRoutes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import About from "../src/Pages/About";
import Services from "./Pages/Services";
import Career from "./Pages/Career";
import Industries from "../src/Pages/Industries";
import Ourproducts from "../src/Pages/Our_products";
import Product from "../src/Component/OurProduct/Products/Product";
import Projectdetails from "../src/Component/Homes/Projects/Projectdetails";
import Allproject from "../src/Component/Homes/Projects/Allproject";
import Request from "./Pages/Request";
import Contact from "./Pages/Contact";

export default function Router() {
  // <HomePage />
  return useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/about", element: <About /> },
    { path: "/services", element: <Services /> },
    { path: "/industries", element: <Industries /> },
    { path: "/our-products", element: <Ourproducts /> },
    // { path: "/product", element: <Product /> },
    { path: "/all-project", element: <Allproject /> },
    { path: "/project-details", element: <Projectdetails /> },
    { path: "/career", element: <Career /> },
    { path: "/request-quote-form", element: <Request /> },
    { path: "/contact", element: <Contact /> },
  ]);
}


