import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Ourproduct.css";
import support from "../../assets/images/support.jpg";
import {
  FaMobileAlt,
  FaConnectdevelop,
  FaFileSignature,
  FaHandHoldingWater,
  FaUsersCog,
  FaRecycle,
  FaCubes,
} from "react-icons/fa";
import { BsTools } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaLandmark,
  FaStudiovinari,
  FaWpforms,
  FaBone,
  FaCompressArrowsAlt,
  FaDraftingCompass,
  FaLongArrowAltRight,
} from "react-icons/fa";

function OurProduct() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <>
      <div className="ur-poroduct">
        <div className="services-t1">
          <Container>
            <div className="section-title" data-aos="zoom-in">
              <h2 className="text-white">Our Products</h2>
              <div className="bar"></div>
              <p className="text-white">
                We offer top-quality products designed to meet our clients' needs
              </p>
            </div>
          </Container>
        </div>
      </div>
      <div className="our-product-update">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <div
                className="single-features"
              >
                <div className="icon">
                  <i>
                    <FaLandmark />
                  </i>
                </div>
                <h3>Banking Solutions for Mercantile</h3>
                <p>
                  We deliver tailored business application solutions for the banking and capital markets industries, including specialized banking solutions for mercantile enterprises.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div
                className="single-features"
              >
                <div className="icon">
                  <i>
                    <FaStudiovinari />
                  </i>
                </div>
                <h3>Jewelry Management System</h3>
                <p>
                  We develop comprehensive systems to automate key jewelry transactions, including purchases, sales, and bill preparation.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div
                className="single-features"
              >
                <div className="icon bg-c679e3">
                  <i>
                    <FaWpforms />
                  </i>
                </div>
                <h3>POS Billing and Stock Management System</h3>
                <p>
                  We offer an advanced POS billing and stock management solution equipped with features such as tax handling, inventory management, add-ons, multi-store support, detailed reporting, invoicing, and barcode integration.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div
                className="single-features"
              >
                <div className="icon bg-c679e3">
                  <i>
                    <FaBone />
                  </i>
                </div>
                <h3>HRMS</h3>
                <p>
                  Our HRMS software enables enterprises and corporations to seamlessly automate their entire post-recruitment HR functions, from onboarding to final separation.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div
                className="single-features"
              >
                <div className="icon bg-eb6b3d">
                  <i>
                    <FaCompressArrowsAlt />
                  </i>
                </div>
                <h3>Asset Maintenance Management System</h3>
                <p>
                  Our Asset Management platform provides complete visibility and control over your assets. It helps you track and manage assets throughout their entire lifecycle.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div
                className="single-features"
              >
                <div className="icon bg-eb6b3d">
                  <i>
                    <FaDraftingCompass />
                  </i>
                </div>
                <h3>Project Management System</h3>
                <p>
                  Our Project Management System offers robust tools to plan, execute, and monitor projects efficiently, ensuring timely delivery and optimal resource utilization.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="our-products-updated-1">
        <Container>
          <Swiper
            slidesPerView={1}
            modules={[Autoplay]}
            autoplay={true}
            spaceBetween={10}
            delay="4500"
            className="mySwiper"
          >
            <SwiperSlide>
              <div
                className="single-features"
              >
                <div className="icon">
                  <i>
                    <FaLandmark />
                  </i>
                </div>
                <h3>Banking Solution for Mercantile</h3>
                <p>
                  We deliver tailored business application solutions for the banking and capital markets industries, including specialized banking solutions for mercantile enterprises.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="single-features"
              >
                <div className="icon">
                  <i>
                    <FaStudiovinari />
                  </i>
                </div>
                <h3>Jewellery Management System</h3>
                <p>
                  We develop comprehensive systems to automate key jewelry transactions, including purchases, sales, and bill preparation.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="single-features"
              >
                <div className="icon bg-c679e3">
                  <i>
                    <FaWpforms />
                  </i>
                </div>
                <h3>POS Billing and Stock Management System</h3>
                <p>
                  We offer an advanced POS billing and stock management solution equipped with features such as tax handling, inventory management, add-ons, multi-store support, detailed reporting, invoicing, and barcode integration.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="single-features"
              >
                <div className="icon bg-c679e3">
                  <i>
                    <FaBone />
                  </i>
                </div>
                <h3>HRMS</h3>
                <p>
                  Our HRMS software enables enterprises and corporations to seamlessly automate their entire post-recruitment HR functions, from onboarding to final separation.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="single-features"
              >
                <div className="icon bg-eb6b3d">
                  <i>
                    <FaCompressArrowsAlt />
                  </i>
                </div>
                <h3>Asset Maintenance Management System</h3>
                <p>
                  Our Asset Management platform provides complete visibility and control over your assets. It helps you track and manage assets throughout their entire lifecycle.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="single-features"
              >
                <div className="icon bg-eb6b3d">
                  <i>
                    <FaDraftingCompass />
                  </i>
                </div>
                <h3>Project Management System</h3>
                <p>
                  Our Project Management System offers robust tools to plan, execute, and monitor projects efficiently, ensuring timely delivery and optimal resource utilization.
                </p>
                {/* <Link to="/Product"> */}
                  Lern More <FaLongArrowAltRight />
                {/* </Link> */}
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </div>
      <section className="our-products-fasted">
        <Container>
          <Row>
            <Col md={6}>
              <div className="ourproduct-25" >
                <img src={support} />
              </div>
            </Col>
            <Col md={6}>
              <div className="our-text-55" >
                <h2>What’s Next? Our Latest Client Success Stories</h2>
                <p>We are passionate about our work, and our clients share that enthusiasm.</p>
              </div>
              <Swiper
                slidesPerView={1}
                delay="4500"
                modules={[Autoplay]}

                autoplay={true}
              >
                <SwiperSlide>
                  <div className="newslide-2">
                    <h1>Fastest-Growing IT Software Agency</h1>
                    <p>
                      We specialize in transforming your business to give you a competitive edge in the digital landscape by leveraging the latest technologies. Let’s collaborate to create your next success story!
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="newslide-2">
                    <h1>Dedicated Client Support</h1>
                    <p>
                      Our software development and support teams are integral to our success, consistently going above and beyond to assist our customers.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="newslide">
        <div className="ml-about-content" data-aos="zoom-in">
          <h2>Technology That Serves Your Purpose</h2>
          <p className="text-white">
            The technology we provide enhances efficiency and flexibility in your workspace, while also boosting the productivity of your business.
          </p>
        </div>
        <Container>
          <Row>
            <Col md={12}>
              <div className="category-wrapper">
                <div className="grid-wrapper">
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <FaMobileAlt />
                        </i>
                      </div>
                      <span className="category-title">Customer Web & Mobile App Development</span>
                    </div>
                  </div>
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <FaConnectdevelop />
                        </i>
                      </div>
                      <span className="category-title">Cloud Computing</span>
                    </div>
                  </div>
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <FaFileSignature />
                        </i>
                      </div>
                      <span className="category-title">Data Science and Analytics</span>
                    </div>
                  </div>
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <BsTools />
                        </i>
                      </div>
                      <span className="category-title">
                        Artificial Intelligence (AI)
                      </span>
                    </div>
                  </div>
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <FaHandHoldingWater />
                        </i>
                      </div>
                      <span className="category-title">Machine Learning (ML)</span>
                    </div>
                  </div>
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <FaUsersCog />
                        </i>
                      </div>
                      <span className="category-title">
                        Database Development
                      </span>
                    </div>
                  </div>
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <FaRecycle />
                        </i>
                      </div>
                      <span className="category-title">
                        Business Intelligence (BI)
                      </span>
                    </div>
                  </div>
                  <div
                    className="category-icons"
                  >
                    <div className="category-item">
                      <div className="item-icon">
                        <i>
                          <FaCubes />
                        </i>
                      </div>
                      <span className="category-title">User Experience (UX) and User Interface (UI) Design</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default OurProduct;
