import React, { useEffect } from "react";
import "./Logoslider.css";
import {Container} from "react-bootstrap";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import gitup from "../../assets/images/logo/gitup.png";
import react from "../../assets/images/logo/react.png";
import php from "../../assets/images/logo/php.png";
import node from "../../assets/images/logo/node.png";
import mysql from "../../assets/images/logo/mysql.png";
import java from "../../assets/images/logo/java.png";
import mdb from "../../assets/images/logo/mongo.png"
import devops from "../../assets/images/logo/devops.png"
import AOS from "aos";
import "aos/dist/aos.css";

function Logoslider() {
 
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
   
      <section className="log-y">
        <Container>
          <Swiper
            slidesPerView={4}
            modules={[Autoplay]}
           
            autoplay={true}
            
            breakpoints={{
              991: { slidesPerView: 5 },
              768: { slidesPerView: 4 },

              376: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
              320: {
                slidesPerView: 2,
                spaceBetween: 44,
              },
              300: {
                slidesPerView: 2,
                spaceBetween: 44,
              },
            }}
            spaceBetween={10}
          >
            <SwiperSlide>
              {" "}
              <img className="sec-img" src={devops} alt="map-imgg" />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="sec-img" src={mdb} alt="map-imgg" />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="sec-img" src={react} alt="map-imgg" />{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="sec-img" src={gitup} alt="map-imgg" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="sec-img" src={node} alt="map-imgg" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="sec-img" src={java} alt="map-imgg" />
            </SwiperSlide>
          </Swiper>
        </Container>
      </section>
   
  );
}
export default Logoslider;
