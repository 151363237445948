import React from "react";
import "./Project.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import website1 from "../../../assets/images/portfolio/website1.png";
import website2 from "../../../assets/images/portfolio/website2.png";
import website3 from "../../../assets/images/portfolio/website3.png";
import website4 from "../../../assets/images/portfolio/website4.png";
import website5 from "../../../assets/images/portfolio/website5.png";
import website6 from "../../../assets/images/portfolio/website6.png";
import website7 from "../../../assets/images/portfolio/website7.png";
import website8 from "../../../assets/images/portfolio/website8.png";
import website9 from "../../../assets/images/portfolio/website9.png";
import website10 from "../../../assets/images/portfolio/website10.png";
import website11 from "../../../assets/images/portfolio/website11.png";
import aap1 from "../../../assets/images/portfolio/aap1.png";
import aap2 from "../../../assets/images/portfolio/aap2.png";
import aap3 from "../../../assets/images/portfolio/aap3.png";
import aap4 from "../../../assets/images/portfolio/aap4.png";
import aap5 from "../../../assets/images/portfolio/aap5.png";
import aap6 from "../../../assets/images/portfolio/aap6.png";
import aap7 from "../../../assets/images/portfolio/aap7.png";
import aap8 from "../../../assets/images/portfolio/aap8.png";
import aap9 from "../../../assets/images/portfolio/aap9.png";
import aap10 from "../../../assets/images/portfolio/aap10.png";
import aap11 from "../../../assets/images/portfolio/aap11.png";
import aap12 from "../../../assets/images/portfolio/aap12.png";
export default function Work() {
  return (
    <>
      <section className="project-slider ml-projects-area">
        <Container>
          <div className="product-25 mb-4">
            <Row>
              <Col md={9} sm={9} xs={12}>

                <div className="product" data-aos="fade-right">
                  <div className="section-title">
                    <h2><strong> Showcasing Our Distinctive Projects

                    </strong>  <br /> Innovative, Interactive, and User-Centric Solutions</h2>

                    {/* Showcasing Our Distinctive Projects  : Innovative, Interactive, and User-Centric Solutions */}
                    <div className="bar"></div>

                  </div>
                  <p>
                    We specialize in delivering dynamic, interactive, and user-friendly projects that set us apart and exceed our clients' expectations
                  </p>
                </div>
              </Col>
              <Col md={3} sm={3} xs={12}>
                <div className="new-prject">
                  <button className=" main-btn btn btn-primary">
                    <Link to="/all-project">View All</Link>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          autoplay={{
            clickable: true,
          }}
          className="mySwiper"
          breakpoints={{
            768: { slidesPerView: 3 },
            767: {
              slidesPerView: 3,
            },
            425: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 1,
            },
          }}
        >

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website3} alt="map-imgg" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap2} alt="map-imgg" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website2} alt="map-imgg" />
            </div>
          </SwiperSlide>


          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap3} alt="map-imgg" />
            </div>
          </SwiperSlide>


          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap1} alt="map-imgg" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap11} alt="map-imgg" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap12} alt="map-imgg" />
            </div>
          </SwiperSlide>


          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap4} alt="map-imgg" />
            </div>
          </SwiperSlide>


          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap8} alt="map-imgg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap6} alt="map-imgg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={aap5} alt="map-imgg" />
            </div>
          </SwiperSlide>


          {/* <SwiperSlide>
              <div className="single-ml-projects-box">
                <img className="port-img" src={aap7} alt="map-imgg" />
              </div>
            </SwiperSlide> */}


          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website6} alt="map-imgg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website4} alt="map-imgg" />
            </div>
          </SwiperSlide>
       

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website9} alt="map-imgg" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website5} alt="map-imgg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-ml-projects-box content">
              <img className="port-img" src={website1} alt="map-imgg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website7} alt="map-imgg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website8} alt="map-imgg" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website11} alt="map-imgg" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-ml-projects-box">
              <img className="port-img" src={website10} alt="map-imgg" />
            </div>
          </SwiperSlide>

        </Swiper>
      </section>
    </>
  );
};

