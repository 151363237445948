import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
import Servicess from "../Component/Service/Servicess";


// const Services = () => {

function Services() {
  return (
    <>
      <Header />
      <Servicess />
      <Footer1 />
      <Footer />
    </>
  );
};

export default Services;
