import React, { useEffect, useState } from "react";
import "./footer.css";
import footer1 from "../../assets/images/footer/footer1.png";
import { Button } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// import "react-toastify/dist/ReactToastify.css";

function Footer() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string()
  //       .email("Invalid email format")
  //       .matches(/^\S*$/, "Empty Whitespace Not Allowed")
  //       .required("Required!"),
  //   }),
  //   onSubmit: async (values) => {
  //     // console.log(values)
  //     showAlert()
  //     formik.resetForm();
  //     // try {
  //     //   const response = await fetch('https://impetrosys.com/new/api/Webservice/savecontacts', {
  //     //     method: 'POST',
  //     //     headers: {
  //     //       'Content-Type': 'application/json',
  //     //     },
  //     //     body: JSON.stringify(values),
  //     //   });

  //     //   if (response.ok) {
  //     //     // Form data sent successfully
  //     //     console.log('Thank you for subscribing!');
  //     //     showAlert()
  //     //     formik.resetForm(); // Reset form after successful submission
  //     //   } else {
  //     //     // Error in sending form data
  //     //     console.log('Error submitting form');
  //     //   }
  //     // } catch (error) {
  //     //   console.log('Error submitting form', error);
  //     // }

  //     try {
  //       const response = await fetch(
  //         `https://impetrosys.com/new/api/Webservice/savecontacts`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Accept: "application/json",
  //           },
  //           body: JSON.stringify(values),
  //         }
  //       );

  //       if (response.ok) {
  //         // Form data sent successfully
  //         // console.log("Form submitted successfully");
  //         showAlert();
  //         formik.resetForm(); // Reset form after successful submission
  //       } else {
  //         // Error in sending form data
  //         console.log("Error submitting form");
  //       }
  //     } catch (error) {
  //       console.log("Error submitting form", error);
  //     }

  //   },

  // });
  // const { onChange } = formik.getFieldProps("email");

  // const emailFieldProps = {
  //   ...formik.getFieldProps("email"),
  //   onChange: (e) => {
  //     e.target.value = e.target.value.trim();
  //     onChange(e);
  //   },
  // };
  // const showAlert = () => {
  //   setapistatus(true);
  // };
  // const [apistatus, setapistatus] = useState(false);
  // const [msg, setmsg] = useState("");

  // useEffect(() => {
  //   if (apistatus === true) {
  //     setmsg("Thank you for subscribing!");
  //     setTimeout(() => {
  //       setapistatus(false);
  //     }, 5000);
  //   } else {
  //     setmsg("");
  //   }
  // }, [apistatus]);
  // // console.log(apistatus, msg);



  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showAlert = (message) => {
    Swal.fire({
      title: "Message Sent",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("email", values.email);
    // const url = `https://controlpanel.impetrosys.com/Api/subscribenewsletter`;
    const url = `${process.env.REACT_APP_BASE_URL}/subscribenewsletter`
    axios
      .post(url, bodyFormData, {
        "Content-Type": "multipart/form-data",
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          showAlert(response.data.message)
        }
        else if (response.data.status_code === 201) {
          showToastMessageone(response.data.message.email);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }


  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });


  return (
    <Container>
      <Row>
        <Col md={6}>

          <div className="email-subs" data-aos="fade-right">
            <img src={footer1} alt="" />
          </div>

        </Col>
        <Col md={6}>
          <div className="newsletter-text">
            <div className="emai-text">
              <h2>Sign Up Our Newsletter</h2>

              <p>Successfully delivered digital products</p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="email-subs">
                <input
                  // {...emailFieldProps}
                  placeholder="Email Your Address"
                  type="email"
                  name="email"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="errormessage">{formik.errors.email}</p>
                )}

                <Button
                  type="submit"
                  name="submit"
                  className="lnk btn-main bg-btn"
                >
                  Subscribe
                </Button>
              </div>
            </form>

            {/* <h6 className="suscription-text">{msg}</h6> */}
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default Footer;
