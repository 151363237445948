// import React from "react";
// import "./Work.css";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { FaFileAlt, FaViruses, FaMedal } from "react-icons/fa";
// import works1 from "../../../assets/images/home/works1.png";
// const Work = () => {
//   return (
//     <>
      // <section className="how-text">
      //   <Container>
      //     <div className="work">
      //       <div className="section-title">
      //         <h2>How It Works</h2>
      //         <div className="bar"></div>
      //       </div>
      //       <p>
      //       System development typically involves several stages: planning, analysis, design, implementation, quality assurance, and deployment. Maintenance is also a critical component that should not be overlooked, as it ensures the continued effectiveness and reliability of the system.
      //       </p>
      //     </div>
      //     <div className="work-steps-box">
      //       <Row>
      //         <Col md={6}>
      //           <div className="work-steps-detail">
      //             <div className="steps-icon">
      //               <i>
      //                 <FaFileAlt />
      //               </i>
      //             </div>
      //             <div className="steps-text">
      //               <h4> Planning & Analysis</h4>
      //               <p className="step-desc">
      //                 Planning and analysis is the first step taken, providing a clearer picture of the project's scope and the anticipated issues, opportunities, and directives that triggered the project. The requirements gathering stage requires teams to collect detailed and precise requirements.
      //               </p>
      //             </div>
      //           </div>
      //           <div className="work-steps-detail mt-4">
      //             <div className="steps-icon">
      //               <i>
      //                 <FaViruses />
      //               </i>
      //             </div>
      //             <div className="steps-text">
      //               <h4> Design and Implementation</h4>
      //               <p className="step-desc">
      //                 In this phase, we implement a system that helps execute real-world tasks with the required data input and enhances productivity. System implementation takes care of system performance, robustness, security, and ease of use. The system must be simple enough for customers to use easily.
      //               </p>
      //             </div>
      //           </div>
      //           <div className="work-steps-detail  mt-4">
      //             <div className="steps-icon">
      //               <i>
      //                 <FaMedal />
      //               </i>
      //             </div>
      //             <div className="steps-text">
      //               <h4> Quality Analysis and Deployment</h4>
      //               <p className="step-desc">
      //                 We know nothing is perfect in this world, but we always strive to make it better and better. Quality assurance is the most important stage, which comes before catering to people.
      //               </p>
      //             </div>
      //           </div>
      //         </Col>
      //         <Col md={6}>
      //           <div className="wor-img" data-aos="zoom-in">
      //             <img src={works1} alt="img7" />
      //           </div>
      //         </Col>
      //       </Row>
      //     </div>
      //   </Container>
      // </section>
//     </>
//   );
// };

// export default Work;












import React from 'react'
import "./Work.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaFileAlt, FaViruses, FaMedal } from "react-icons/fa";
import works1 from "../../../assets/images/home/works1.png";
export default function Work() {
  return (
    <>
          <section className="how-text">
        <Container>
          <div className="work">
            <div className="section-title">
              <h2>How It Works</h2>
              <div className="bar"></div>
            </div>
            <p>
            System development typically involves several stages: planning, analysis, design, implementation, quality assurance, and deployment. Maintenance is also a critical component that should not be overlooked, as it ensures the continued effectiveness and reliability of the system.
            </p>
          </div>
          <div className="work-steps-box">
            <Row>
              <Col md={6}>
                <div className="work-steps-detail">
                  <div className="steps-icon">
                    <i>
                      <FaFileAlt />
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4> Planning & Analysis</h4>
                    <p className="step-desc">
                      {/* Planning and analysis is the first step taken, providing a clearer picture of the project's scope and the anticipated issues, opportunities, and directives that triggered the project. The requirements gathering stage requires teams to collect detailed and precise requirements. */}
                      Planning and analysis clarify the project's scope, issues, and opportunities, while requirements gathering ensures precise and detailed needs are collected.
                    </p>
                  </div>
                </div>
                <div className="work-steps-detail mt-4">
                  <div className="steps-icon">
                    <i>
                      <FaViruses />
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4> Design and Implementation</h4>
                    <p className="step-desc">
                      {/* In this phase, we implement a system that helps execute real-world tasks with the required data input and enhances productivity. System implementation takes care of system performance, robustness, security, and ease of use. The system must be simple enough for customers to use easily. */}
                   
                   This phase focuses on implementing a user-friendly system that enhances productivity, ensuring performance, security, and ease of use.
                    </p>
                  </div>
                </div>
                <div className="work-steps-detail  mt-4">
                  <div className="steps-icon">
                    <i>
                      <FaMedal />
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4> Quality Analysis and Deployment</h4>
                    <p className="step-desc">
                      {/* We know nothing is perfect in this world, but we always strive to make it better and better. Quality assurance is the most important stage, which comes before catering to people. */}
                    We recognize nothing is perfect, but we strive for continuous improvement. Quality assurance is crucial before reaching our customers.
                    
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="wor-img" data-aos="zoom-in">
                  <img src={works1} alt="img7" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    
    </>
  )
}
