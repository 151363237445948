import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Row, Col, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";

function Contactform() {

  // mobile number state
  const [mobilenumber, setmobilenumber] = useState("");
  const [valid, setvalid] = useState(false);
  const [mobilevalid, setmobilevalid] = useState(false);
  const [mobilevalidationerror, setmobilevalidationerror] = useState("");

  const mobilevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      setmobilevalidationerror("Please Enter A valid Mobile Number");
      setmobilevalid(false);
    } else {
      setmobilevalidationerror("");
      setmobilevalid(true);
    }
  };


  //toast messages function
  // const showToastMessage = (data) => {
  //   toast.success(data, {
  //     position: toast.POSITION.TOP_CENTER,
  //   });
  // };
  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showAlert = (message) => {
    Swal.fire({
      title: "Message Sent",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("subject", values.subject);
    bodyFormData.append("contact", mobilenumber);
    bodyFormData.append("message", values.message);
    const url = `${process.env.REACT_APP_BASE_URL}/sendcontacts`;
    axios
      .post(url, bodyFormData, {
        "Content-Type": "multipart/form-data",
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          showAlert(response.data.message)
          setmobilenumber("")
        }
        else if (response.data.status_code === 201) {
          showToastMessageone(response.data.message.name);
          showToastMessageone(response.data.message.email);
          showToastMessageone(response.data.message.subject);
          showToastMessageone(response.data.message.contact);
          showToastMessageone(response.data.message.message);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      contact: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      subject: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
      message: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      formik.resetForm();
    },
  });


  return (
    <>
      <div className="contctform-30">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={6}>
              <label className="Contactform-lb">Enter Name</label>
              <Form.Group>
                <Form.Control
                  placeholder="Enter Your Name"
                  type="text"
                  name="name"
                  autoComplete="off"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="errormessage">{formik.errors.name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <label className="Contactform-lb">Enter Email</label>
              <Form.Group>
                <Form.Control
                  placeholder="Enter Your Email"
                  type="email"
                  name="email"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="errormessage">{formik.errors.email}</p>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <label className="Contactform-lb">Enter Mobile Number</label>
              <PhoneInput
                country={"in"}
                // onlyCountries={["in"]}
                value={mobilenumber}
                // disableDropdown
                // countryCodeEditable={false}
                onChange={(phone, value) => {
                  setmobilenumber(phone);
                  mobilevalidation(phone);
                }}
                prefix="+"
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  autoComplete: "off",
                }}
                isValid={(validmobilenumber) => {
                  if (
                    !new RegExp(/^[0-9+].{6,12}$/).test(
                      validmobilenumber
                    )
                  ) {
                    setvalid(false);

                    return false;
                  } else {
                    setvalid(true);

                    return true;
                  }
                }}
              />
              <span>
                {mobilevalid ? (
                  <></>
                ) : (
                  <>
                    <p className="mobile-error">
                      {mobilevalidationerror}
                    </p>
                  </>
                )}
              </span>
            </Col>
            <Col md={6}>
              <label className="Contactform-lb">Enter Subject</label>
              <Form.Group>
                <Form.Control
                  placeholder="Enter Your Subject"
                  type="text"
                  name="subject"
                  autoComplete="off"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                />
                {formik.errors.subject && formik.touched.subject && (
                  <p className="errormessage">{formik.errors.subject}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <label className="Contactform-lb">Enter Message </label>
              <Form.Control
                placeholder="Enter Your Message"
                as="textarea"
                style={{ height: "108px" }}
                type="message"
                name="message"
                autoComplete="off"
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
              />
              {formik.errors.message && formik.touched.message && (
                <p className="errormessage">{formik.errors.message}</p>
              )}
              <div className="contact-7">
                <button className=" main-btn btn btn-primary">
                  SEND
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        <ToastContainer />
      </div>

    </>
  );
}

export default Contactform;
