
const MenuItems = [
    {
        id: 1,
        image: "http://localhost:3000/static/media/aap6.5777fe35cf27f27a6a4e.png",
        name: "FAMILY FM LTD.Antigua",
        category: "app",
        description: 'Android App -> iOS App'
    },

    {
        id: 2,
        image: "http://localhost:3000/static/media/aap1.461408a6afe46edde8f3.png",
        name: "Bct Pay",
        category: "app",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 3,
        image: "http://localhost:3000/static/media/aap11.4fd4290b3e96faeb1530.png",
        name: "Soundchat Radio",
        category: "app",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 4,
        image: "http://localhost:3000/static/media/aap3.a8ccbc8d7305df8602cb.png",
        name: "Reegur",
        category: "app",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 5,
        image: "http://localhost:3000/static/media/aap2.0e6ccab5af8504fe675e.png",
        name: "Payroos",
        category: "app",
        description: 'Android App -> iOS App '
    },
    {
        id: 6,
        image: "http://localhost:3000/static/media/aap6.5777fe35cf27f27a6a4e.png",
        name: "Time less ",
        category: "app",
        description: 'Android App -> iOS App'
    },
    {
        id: 7,
        image: "http://localhost:3000/static/media/aap8.65fb423d4bbb63ac96f2.png",
        name: "Rock vibe gospal radio",
        category: "app",
        description: 'Android App -> iOS App '
    },
    {
        id: 8,
        image: "http://localhost:3000/static/media/aap4.8b670e92e228b45a0760.png",
        name: "Catholic radio",
        category: "app",
        description: 'Android App -> iOS App '
    },


    {
        id: 9,
        image: "http://localhost:3000/static/media/website1.4ce9a60cea0be8b90b12.png",
        name: "Pragati manav seva sansthan",
        category: "Website",
        description: 'Website '
    },
    {
        id: 10,
        image: "http://localhost:3000/static/media/website2.05d4f22d576f88153a13.png",
        name: "Mahakal takniki shiksha samiti",
        category: "Website",
        description: 'Website '
    },

    {
        id: 11,
        image: "http://localhost:3000/static/media/website3.d608ababe50069ae43d7.png",
        name: "Pettershell",
        category: "Website",
        description: 'Website '
    },
    // {
    //     id: 12,
    //     image: "http://localhost:3000/static/media/website9.74f84a43.png",
    //     name: "Gihlot consultant",
    //     category: "Website",
    //     description: 'Website '
    // },
    {
        id: 13,
        image: "http://localhost:3000/static/media/website11.98567283e4242bcac9be.png",
        name: "Film Director",
        category: "Website",
        description: 'Website '
    },
    {
        id: 14,
        image: "http://localhost:3000/static/media/website5.8ef76dbda6f814269720.png",
        name: "Gyanav Solution Private Limited ",
        category: "Website",
        description: 'website '
    },
    // {
    //     id: 15,
    //     image: "http://localhost:3000/static/media/website6.16a0d9ee.png",
    //     name: "Seemoss",
    //     category: "Website",
    //     description: 'website '
    // },
    {
        id: 16,
        image: "http://localhost:3000/static/media/website7.691bc2caf0c8da066946.png",
        name: "Sanatan Express Logistics",
        category: "Website",
        description: 'website '
    },
    {
        id: 17,
        image: "http://localhost:3000/static/media/website8.bfcf6a058d92863c9931.png",
        name: "Legis facilitar Law Firm",
        category: "Website",
        description: 'website '
    },
    {
        id: 17,
        image: "http://localhost:3000/static/media/website9.7b30141b8daf5509ed11.png",
        name: "kccorganicminerals",
        category: "Website",
        description: 'website '
    },

    {
        id: 18,
        image: "http://localhost:3000/static/media/aap6.5777fe35cf27f27a6a4e.png",
        name: "FAMILY FM LTD.Antigua",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 19,
        image: "http://localhost:3000/static/media/aap1.461408a6afe46edde8f3.png",
        name: "Bct Pay",
        category: "ios",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 20,
        image: "http://localhost:3000/static/media/aap11.4fd4290b3e96faeb1530.png",
        name: "Soundchat Radio",
        category: "ios",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 21,
        image: "http://localhost:3000/static/media/aap3.a8ccbc8d7305df8602cb.png",
        name: "Reegur",
        category: "ios",
        description: 'Android App -> iOS App -> Website'
    },
    {
        id: 21,
        image: "http://localhost:3000/static/media/aap2.0e6ccab5af8504fe675e.png",
        name: "Payroos",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 22,
        image: "http://localhost:3000/static/media/aap5.6b052aef06bf9a2492e5.png",
        name: "Time less ",
        category: "ios",
        description: 'Android App -> iOS App'
    },
    {
        id: 23,
        image: "http://localhost:3000/static/media/aap8.65fb423d4bbb63ac96f2.png",
        name: "Rock vibe gospal radio",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 24,
        image: "http://localhost:3000/static/media/aap4.8b670e92e228b45a0760.png",
        name: "Catholic radio",
        category: "ios",
        description: 'Android App -> iOS App '
    },
    {
        id: 25,
        image: "http://localhost:3000/static/media/website11.98567283e4242bcac9be.png",
        name: "Film Director",
        category: "website",
        description: 'website'
    },

];



export default MenuItems;