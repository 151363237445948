import { useEffect, useState } from "react";
import { NavLink,Link,useLocation } from "react-router-dom"
import {Navbar} from 'react-bootstrap'
import logo from "../../assets/images/logo.png";
import "./header.css"
import { FiMenu, FiX } from "react-icons/fi";

// const Header = () => {
  export default function Header(){
  const currentlocation = useLocation()
  // console.log(currentlocation)
  const [path,setpath]=useState("/")
  useEffect(()=>{
setpath(currentlocation.pathname)
  },[currentlocation.pathname])
  const [click, setClick] = useState(true);
  window.addEventListener(
    "resize",
    function (event) {
      if (window.innerWidth <= 991) {
        setClick(false);
      } else {
        setClick(true);
      }
    },
    true
  );
  useEffect(() => {
    if (window.innerWidth <= 991) {
      setClick(false);
    } else {
      setClick(true);
    }
  }, [window.innerWidth]);
 ///
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };
  return (
    <>
       <Navbar
        bg="light"
        expand={click}
        style={{ position: "sticky", top: 0, zIndex: 1020 }}
      >
      <nav className="navbar">
      <Link to="/" className="nav-logo">
      <img src={logo}  alt='logo' />
      </Link>
      <div onClick={handleClick} className="nav-icon">
        {open ? <FiX /> : <FiMenu />}
      </div>
      <ul className={open ? "nav-links active" : "nav-links "}>
        {/* <li className="nav-item">
       
          <NavLink to="/" className={(path==='/') ? 'nav-link activeheader' : 'nav-link'} 
          onClick={closeMenu}>
            Home
          </NavLink>
        </li>
        */}
        <li className="nav-item">
          <NavLink to="/about" className={(path==='/about') ? 'nav-link activeheader' : 'nav-link'} onClick={closeMenu}>
          Who We Are
          </NavLink>
        </li> 
        <li className="nav-item">
          <NavLink to="/services" className={(path==='/services') ? 'nav-link activeheader' : 'nav-link'} onClick={closeMenu}>
          Services
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/industries" className={(path==='/industries') ? 'nav-link activeheader' : 'nav-link'} onClick={closeMenu}>
          Industries
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/our-products" className={(path==='/our-products') ? 'nav-link activeheader' : 'nav-link'}  onClick={closeMenu}>
          Our Products
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to="/Career" className={(path==='/Career') ? 'nav-link activeheader' : 'nav-link'} onClick={closeMenu}>
          Career
          </NavLink>
        </li> */}
        {/* <li className="nav-item">
          <NavLink to="/Contact" className={(path==='/Contact') ? 'nav-link activeheader' : 'nav-link'} onClick={closeMenu}>
          Contact
          </NavLink>
        </li> */}
        <li className="nav-item-1">
          <Link to="/request-quote-form" className="nav-link" onClick={closeMenu}>
          Request A Quote
          </Link>
        </li>
      </ul>
    </nav>
    </Navbar> 
    </>
  ) 
}

// export default Header
