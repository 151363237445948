import React, { useEffect } from "react";
import "./About.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaHeadphones,
  FaReceipt,
  FaRegSmile,
  FaTabletAlt,
} from "react-icons/fa";
import { FaTransgenderAlt, FaMedapps, FaRegFile } from "react-icons/fa";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Container, Row, Col } from "react-bootstrap";
import team1 from "../../assets/images/team/team1.png";
import team2 from "../../assets/images/team/team2.png";
import team3 from "../../assets/images/team/team3.png";
import team4 from "../../assets/images/team/team4.png";
import team5 from "../../assets/images/team/team5.png";
import team77 from "../../assets/images/team/team77.png";
import { TfiWorld } from "react-icons/tfi";
import { RiTeamLine, RiUserSettingsLine } from "react-icons/ri";
import { GiBrain } from "react-icons/gi";


function About1() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <>
      {/* <Topheader/> */}
      {/* <Header />
      <AboutStyles> */}
      <div className="about-area ptb-80">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="ml-about-content" data-aos="zoom-in">
                <span className="sub-title">About Us</span>
                <h2>Who We Are</h2>
                <div className="bar"></div>
                <p>
                  IMPETROSYS established since over a decade with vision to
                  achieve global IT services leadership in providing
                  value-added high quality IT solutions to our clients, with
                  expertise in developing custom web & mobile applications for
                  businesses of all sizes and industries Over the years we
                  have managed to build a solid team of software outsourcing
                  professionals that come from various backgrounds and expand
                  the creative potential of the company.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="histry-text">
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <div className="about-text1  our-mission">
                <i>
                  <FaTransgenderAlt />
                </i>
                <h3>Our Mission</h3>
                <p>
                  To empower businesses through technology by providing innovative, reliable,
                  and efficient solutions. We strive to exceed expectations and foster long-term partnerships built on trust,
                  transparency.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="about-text1 our-vision">
                <i>
                  {" "}
                  <FaMedapps />
                </i>

                <h3>Our vision</h3>
                <p>
                  Our vision We strive to become the world's most admired and
                  valuable business partner and to make every possible move in
                  the upcoming years for our client’s success.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="about-text1 our-histtory">
                <i>
                  <FaRegFile />
                </i>

                <h3>Our History</h3>
                <p>
                  IMPETROSYS established since over a decade with vision to
                  achieve global IT services leadership in providing
                  value-added high quality IT solutions to our clients.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="app_featured_area about-usBg ptb-50-100">
        <div className="container">
          <div className="row  app_feature_info  honest_section1">
            <div className="col-lg-6">
              <div className="app_featured_content mobile_cpmpany11">
                <h4 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">
                  {/* Top Mobile App &amp; Web Development Company in India */}

                  Leading IT Software Consultant in India

                </h4>

                <p className="f_400">
                Welcome to IMPETROSYS, your trusted partner in IT software consulting and solutions in India. As a leading IT software consultant, we are dedicated to empowering businesses with cutting-edge technology and strategic insights to drive their digital transformation and achieve their goals.</p>

               
                  <p className="f_400">
                  <br/>
                  Partner with us to leverage our expertise and experience in IT software consulting. Contact us today to explore how we can help you achieve your technology objectives and drive your business forward.
                  </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="app_fetured_item">
                <Row>
                  <Col md={6} sm={6} xs={6}>
                    <div
                      className="app_item item_one"
                      data-parallax='{"x": 0, "y": 50}'
                    >
                      <i className="ti-headphone">
                        <GiBrain />
                      </i>

                      <h3 className="f_p f_400 f_size_16 w_color l_height45">
                        {/* 24x7 Support */}
                        Innovative Solutions
                      </h3>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <div
                      className="app_item item_two"
                      data-parallax='{"x": 0, "y": -30}'
                    >
                      <i className="ti-headphone">
                        <RiUserSettingsLine />
                      </i>

                      <h3 className="f_p f_400 f_size_16 w_color l_height45">
                      Client-Centric Approach                      </h3>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <div
                      className="app_item item_three"
                      data-parallax='{"x": 50, "y": 10}'
                    >
                      <i className="ti-headphone">
                        <RiTeamLine />
                      </i>

                      <h3 className="f_p f_400 f_size_16 w_color l_height45">
                      Experienced Team                      </h3>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={6}>
                    <div
                      className="app_item item_four"
                      data-parallax='{"x": -20, "y": 50}'
                    >
                      <i className="ti-headphone">
                        <TfiWorld />
                      </i>

                      <h3 className="f_p f_400 f_size_16 w_color l_height45">
                      Global Reach
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section-team">
        <div className="section-title" data-aos="zoom-in">
          <h2>Our Dedicated Professionals Who Deliver Unique solutions</h2>
          <div className="bar"></div>
          <p className="mb-3">
            At IMPETROSYS, our greatest asset is our team. We pride ourselves on our talented and dedicated professionals
            who drive our success and deliver exceptional results for our clients. Our team resources are the backbone of our operations,
            bringing diverse skills, experience, and expertise to every project.
          </p>
        </div>
        <Swiper
          slidesPerView={5}
          modules={[Autoplay]}

          autoplay={true}
          // className="mySwiper"
          spaceBetween={10}
          breakpoints={{
            1024: {
              slidesPerView: 4,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },

            575: {
              slidesPerView: 2,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },
          }}
        >
          <SwiperSlide>
            {" "}
            <div className="single-person">
              <div className="person-image">
                <img src={team3} alt="map-imgg" />
              </div>

              <div className="person-info">
                <h3>Mr. Suraj Pandagre</h3>
                <p>Sr. FullStack Developer</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-person">
              <div className="person-image">
                <img src={team4} alt="map-imgg" />
              </div>

              <div className="person-info">
                <h3>Miss. Shailesh Bhadoriya</h3>
                <p> Sr. IOS developer.</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-person">
              <div className="person-image">
                <img src={team1} alt="map-imgg" />
              </div>

              <div className="person-info">
                <h3>Miss. Dipali Jain</h3>
                <p> Sr. Web designer.</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-person">
              <div className="person-image">
                <img src={team2} alt="map-imgg" />
              </div>

              <div className="person-info">
                <h3>Mr. Kamlesh Rajpoot</h3>
                <p> Sr. FullStack developer.</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            {" "}
            <div className="single-person">
              <div className="person-image">
                <img src={team5} alt="map-imgg" />
              </div>

              <div className="person-info">
                <h3>Mr. Raj Kumar Patel</h3>
                <p> Sr.Flutter Developer.</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div className="single-person">
              <div className="person-image">
                <img src={team77} alt="map-imgg" />
              </div>

              <div className="person-info">
                <h3>Miss.Rashmi Lodhi</h3>
                <p> Jr.Frontend Developer.</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <Counter />
      </AboutStyles>
      <Footer1 />
      <Logoslider />
      <Footer /> */}
    </>
  );
}

export default About1;
