import React, { useState, useEffect, useRef } from "react";
import Pagination from "react-bootstrap/Pagination";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
import "./Career.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Container } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { Row, Col, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Vacancy() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [show, setShow] = useState(false);
  const [titlejob, setTitleJob] = useState("")

  // Create a ref for the file input
  const fileInputRef = useRef(null);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  //   pagination
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  // toast message function
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showAlert = (message) => {
    Swal.fire({
      title: "Thank You !",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  //this is uses for the pdf/image upload in database.
  const [resumefile, setResumefile] = useState("");

  // image upload validation function 
  const upload = (event) => {
    const imageFile = event.target.files[0];
    setResumefile(imageFile);
    if (!imageFile) {
      showToastMessage("Please select a file");
      return false;
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG|doc|docx|pdf|txt)$/)) {
      showToastMessage("Please select only .pdf, .txt, .doc");
      return false;
    }
  };


  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("job", titlejob);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("message", values.message);
    bodyFormData.append("file", resumefile);
    const url = `${process.env.REACT_APP_BASE_URL}/applyforjob`;
    axios
      .post(url, bodyFormData, {
        "Content-Type": "multipart/form-data",
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          setResumefile("")

          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
          showAlert(response.data.message)
          setShow(false)
        }
        else if (response.data.status_code === 201) {
          showToastMessageone(response.data.message.name);
          showToastMessageone(response.data.message.email);
          showToastMessageone(response.data.message.job);
          showToastMessageone(response.data.message.phone);
          showToastMessageone(response.data.message.message);
          showToastMessageone(response.data.message.file);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      phone: Yup.string()
        .required(" Required")
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Phone number is not valid"
        ),

      // job: Yup.string()
      //   .required("Required!"),

      message: Yup.string()
        .required("Required!"),
    }),
    onSubmit: (values) => {
      setResumefile("")
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      handleSubmit(values);
      formik.resetForm();
    },
  });


  // useEffect(() => {
  //   console.log(resumefile);
  // }, [resumefile]);

  // function checkextension() {
  //   var file = document.querySelector("#fUpload");
  //   if (/\.(jpe?g|png|gif|doc|docx|pdf)$/i.test(file.files[0].name) === false) {
  //     Swal("Please upload .PDF .DOCX .DOC .JPG .JPEG .XLS");
  //   }
  // }

  // const handleChange = (e) => {
  //   setResumefile(e.target.files[0]);
  //   checkextension();
  // };



  const [jobdata, setJobdata] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getcareerjobs`)
      .then((response) => {
        if (response.status == 200) {
          setJobdata(response.data.jobslist)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  return (
    <>
      <div className="vacancy">
        <div className="container">
          <div className="text-center mb-2">
            <h3>Jobs opening</h3>
            <p className="lead">Grow your career with us</p>
          </div>
          <div className="row">
            {jobdata.map((element, index) => {
              return (
                <div className="col-md-6 col-lg-4" key={index}>
                  <div className="services-item">
                    <div className="services-text">
                    <div className="career-title-box">
                    <h3 className="title">{element.title}</h3>
                      <p>{element.description}</p>
                    </div>

                      <div className="job-portal">
                        {element.category.map((category, index) => {
                          return (
                            <span key={index} className="badge bg-secondary">{category}</span>
                          )
                        })}
                      </div>
                      <ul className="course-meta-details list-inline  w-100">
                        <li>
                          <span> <FaUserTie /></span>
                          <p>{element.experience}</p>
                        </li>
                        <li>
                          <span><FaRegClock /></span>
                          <p>{element.job_type}</p>
                        </li>
                        <li>
                          <span><FaMapMarkerAlt /></span>
                          <p>{element.job_location}</p>
                        </li>
                      </ul>
                      <a
                        onClick={() => {
                          setShow(true);
                          setTitleJob(element.title);
                        }}
                        className="btn btn-primary stretched-link"
                      >
                        Apply
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {/* <Pagination>
            <Pagination.First />
            {items}
            <Pagination.Last />
          </Pagination> */}
        </div>
        <ToastContainer />
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Full Stack Developer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6} sm={6}>
                <label className="Request_field">Name</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="Enter Your Name"
                    autoComplete="off"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <p className="errormessage">{formik.errors.name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <label className="Request_field">Email</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className="errormessage">{formik.errors.email}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <label className="Request_field">Phone</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="phone"
                    name="phone"
                    country="US"
                    placeholder="Enter Your Mobile Numner"
                    autoComplete="off"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <p className="errormessage">{formik.errors.phone}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <label className="Request_field">Apply for the job</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="job"
                    placeholder="Enter Your Job Title"
                    autoComplete="off"
                    value={titlejob}
                    onChange={(e) => setTitleJob(e.target.value)}
                  />
                  {/* {formik.errors.job && formik.touched.job && (
                    <p className="errormessage">{formik.errors.job}</p>
                  )} */}
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="field file-upload">
                  <label htmlFor="uploadfile" className="Request_field">Upload Resume</label>
                  <input
                    type="file"
                    className="form-control"
                    ref={fileInputRef}
                    // acceptType={["pdf", "txt", "doc", "docx"]}
                    id="uploadfile"
                    onChange={(e) => {
                      upload(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <label className="Request_field">Message</label>
                <Form.Control
                  className="form-control"
                  as="textarea"
                  placeholder="Enter Your Message"
                  style={{ height: "125px" }}
                  type="message"
                  name="message"
                  autoComplete="off"
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {formik.errors.message && formik.touched.message && (
                  <p className="errormessage">{formik.errors.message}</p>
                )}
              </Col>
            </Row>
            <div className="form-bt">
              <Button type="Submit" className="main-btn btn btn-primary">
                APPLY NOW
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Vacancy;
