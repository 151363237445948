// import React from "react";
// import "./About.css";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import about3 from "../../../assets/images/about3.png";
// const About = () => {
//   return (
//     <>
//     <div className="about-area">
//           <Container>
//             <Row>
//               <Col lg={6}>
//                 <div className="home-about-img" data-aos="fade-right">
//                   <img src={about3} alt="image3" className="img-about" />
//                 </div>
//               </Col>
//               <Col lg={6}>
//                 <div className="Home-about-content"  >
//                   <span className="home-abouts-30">Do you want to know ?</span>
//                     <h2><strong>IMPETROSYS</strong> play a crucial role by providing tailored solutions and support to enhance efficiency, productivity, and competitiveness</h2>

//                     <div className="bar"></div>
//                   <p>
//                   <strong>Automation of Processes:</strong> Software automates routine tasks, reducing the need for manual intervention. This increases efficiency and allows employees to focus on higher-value activities.
//                   </p>
//                   <p>
//                   <strong> Cost Savings:</strong> Investing in the right software solutions can lead to significant cost savings over time by improving operational efficiency, reducing waste, and optimizing resources.
//                   </p>
//                   <p>
//                   <strong>Data Management and Analysis:</strong>  Software solutions help organizations collect, store, and analyze data, providing insights that can drive strategic decision-making and improve performance.
//                   </p>
//                   {/* <p>
//                   <strong> Resource Management: </strong>Software helps in planning and managing resources efficiently, whether it’s human resources, finances, or physical assets. This leads to optimal utilization and reduced waste.
//                   </p> */}
//                   {/* <p>
//                   <strong>Customer Relationship Management: </strong> CRM software enables organizations to manage interactions with customers, improve customer service, and enhance customer satisfaction and loyalty.
//                   </p> */}
//                   {/* <p>
//                   <strong>Security:</strong>  Software plays a critical role in protecting organizational data and systems from cyber threats. Security software helps detect, prevent, and respond to security incidents.
//                   </p> */}
//                   <p>
//                   <strong>Scalability:</strong>  Software solutions can be easily scaled to accommodate business growth. This flexibility allows organizations to adapt to changing demands and expand without significant infrastructure changes.
//                   </p>
               
//                   {/* <p>
//                     {" "}
//                     Our expertise team can help your business to run flawlessly.
//                     We develope a fully customise system for your busines
//                     process that can be tracked at any intance and generate a
//                     perofrmce report.
//                   </p> */}
//                 </div>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//     </>
//   );
// };

// export default About;





import React from "react";
import "./About.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import about3 from "../../../assets/images/about3.png";

export default function About() {
  return (
    <>
    
        <div className="about-area">
          <Container>
             <Row>
               <Col lg={6}>
                 <div className="home-about-img" data-aos="fade-right">
                   <img src={about3} alt="image3" className="img-about" />
                </div>
              </Col>
               <Col lg={6}>
                 <div className="Home-about-content"  >
                   <span className="home-abouts-30">Do you want to know ?</span>
                    <h2 className="about-tile-h2-desktop"><strong>IMPETROSYS</strong> play a crucial role by providing tailored solutions and support to enhance efficiency, productivity, and competitiveness</h2>

                    <h2 className="about-tile-h2-mobile"><strong>IMPETROSYS</strong> play a crucial role by providing tailored solutions</h2>
                    <div className="bar"></div>
                   <p>
                  <strong>Automation</strong> 
                  Streamlines tasks, boosts efficiency, and frees employees for higher-value work.
                  {/* Software automates routine tasks, reducing the need for manual intervention. This increases efficiency and allows employees to focus on higher-value activities. */}
                   </p>
                  <p>
                  <strong> Cost Savings</strong>
                  Enhances efficiency, reduces waste, and optimizes resources, leading to long-term savings.
                   {/* Investing in the right software solutions can lead to significant cost savings over time by improving operational efficiency, reducing waste, and optimizing resources. */}
                   </p>
                   <p>
                   <strong>Data Management and Analysis:</strong> 
                   Facilitates data collection and analysis for informed decision-making and improved performance.
                    {/* Software solutions help organizations collect, store, and analyze data, providing insights that can drive strategic decision-making and improve performance. */}
                   </p>
                   {/* <p>
                   <strong> Resource Management: </strong>Software helps in planning and managing resources efficiently, whether it’s human resources, finances, or physical assets. This leads to optimal utilization and reduced waste.
                   </p> */}
                  {/* <p>
                  <strong>Customer Relationship Management: </strong> CRM software enables organizations to manage interactions with customers, improve customer service, and enhance customer satisfaction and loyalty.
                   </p> */}
                   {/* <p>
                   <strong>Security:</strong>  Software plays a critical role in protecting organizational data and systems from cyber threats. Security software helps detect, prevent, and respond to security incidents.
                   </p> */}
                  <p>
                  <strong>Scalability</strong>  
                  {/* Software solutions can be easily scaled to accommodate business growth. This flexibility allows organizations to adapt to changing demands and expand without significant infrastructure changes. */}
                   Adapts to business growth, enabling seamless expansion without major infrastructure changes.
                   </p>
               
                 {/* <p>
                  {" "}
                    Our expertise team can help your business to run flawlessly.
                    We develope a fully customise system for your busines
                    process that can be tracked at any intance and generate a
                    perofrmce report.
                  </p> */}
                 </div>
               </Col>
             </Row>
           </Container>
         </div>
    
    
    </>
  )
}
