import React from "react";
import "./Projetdetails.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../../Common/header/Header";
import Footer from "../../../Common/footer/Footer";
import Footer1 from "../../../Common/footer/Footer1";
import { FaAppleAlt, FaGlobe, FaHtml5, FaCss3Alt, FaReact, FaBootstrap, FaNodeJs } from "react-icons/fa";
import { FcAndroidOs } from "react-icons/fc";
import aap3 from "../../../assets/images/portfolio/aap3.png";
import { DiMongodb } from "react-icons/di";

// const Projectdetails = () => {
function Projectdetails() {
  return (
    <>
      <Header />
      <section className="project-details bg-light-gray">
        <Container>
          <Row>
            <Col md={7} xl={7}>
              <div className="caseStudyDetail">
                <h2>An On-Demand Grocery Delivery Application</h2>
                <ul className="tagList">
                  <li>AI PLANNING</li>
                  <li>ANALYSIS</li>
                  <li>UX/UI DESIGN</li>
                  <li>APP DEVELOPMENT</li>
                </ul>
              </div>
              <ul className="platform">
                <li className="black">
                  <span>
                    <FaAppleAlt />
                  </span>
                  iOS
                </li>
                <li className="green">
                  <span>
                    <FcAndroidOs
                      style={{
                        fontSize: "25px",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    />
                  </span>
                  Android
                </li>
                <li className="purple">
                  <em className="icon-web">
                    {" "}
                    <FaGlobe />{" "}
                  </em>
                  Website
                </li>
              </ul>
            </Col>
            <Col md={5} xl={5}>
              <div className="project-new-img">
                <img src={aap3} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="project-two-section">
        <Container>
          <Row>
            <Col md={6}>
              <div className="project-two">
                <h2>Sound chat radio </h2>
                <p>
                  Sound chat radio is a comprehensive on-demand grocery delivery
                  app that allows users to shop for groceries to daily
                  necessities and get it delivered on the doorsteps. The user
                  can directly explore the variety of grocery items and order
                  them at their convenience. After getting the order, the items
                  are picked up by the delivery person and delivered to the
                  mentioned delivery address. For consumers, various payment
                  options are available to ensure easy checkout including cash
                  on arrival, e-wallets, credit and debit cards, net banking,
                  etc.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="aboutSec_rightBox">
                <ul className="diversityList list-unstyled">
                  <li>
                    {" "}
                    <span className="diversityList_title">Client’s Location</span>
                    <p>United Arab Emirates</p>
                  </li>
                  <li>
                    {" "}
                    <span className="diversityList_title">Development Time</span>
                    <p>06 Months</p>
                  </li>
                  <li>
                    {" "}
                    <span className="diversityList_title">Target Users</span>
                    <p>
                      Ecommerce brands, users to shop for groceries to daily
                      necessities
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="project-third">
        <Container>
          <Row>
            <Col md={12}>
              <div className="project-laguage">
                <h2>Technologies Leveraged</h2>
                <p className="text-white">We leveraged the strength of robust and secure technologies, tools, and
                  frameworks used in the app/web
                  to escalate the creation of new features and functionalities.</p>
              </div>
              <Row>
                <Col md={2}>
                  <div className="leveragedSec_cnt">
                    <i className="html">
                      <FaHtml5 />
                    </i>

                    <h5>Html5</h5>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="leveragedSec_cnt">
                    <i className="css">
                      <FaCss3Alt />
                    </i>

                    <h5>Css3</h5>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="leveragedSec_cnt">
                    <i className="react">
                      <FaReact />
                    </i>

                    <h5>React js</h5>
                  </div>
                </Col>
                <Col md={2}>
                  <div className="leveragedSec_cnt">
                    <i className=" bootstrap">
                      < FaBootstrap />
                    </i>

                    <h5>Bootstrap</h5>
                  </div>

                </Col>

                <Col md={2}>
                  <div className="leveragedSec_cnt">
                    <i className="node">
                      <  FaNodeJs />
                    </i>

                    <h5>Node js</h5>
                  </div>

                </Col>
                <Col md={2}>
                  <div className="leveragedSec_cnt">
                    <i className="Mongodb">
                      <  DiMongodb />
                    </i>

                    <h5>Mongodb</h5>
                  </div>

                </Col>

              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer1 />
      <Footer />
    </>
  );
};

export default Projectdetails;
