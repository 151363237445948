import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";

function Rqform() {
  const [startDate, setStartDate] = useState(new Date());
  const [country, setcountry] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [valid, setvalid] = useState(false);
  const [mobilevalid, setmobilevalid] = useState(false);
  const [phonevalid, setphonevalid] = useState(false);
  const [mobilevalidationerror, setmobilevalidationerror] = useState("");
  const [phonevalidationerror, setphonevalidationerror] = useState("");
  const [servicesdata, setServicesdata] = useState([])
  const [servicestype, setServicesType] = useState("")
  const [resumefile, setResumefile] = useState(null);
  // Create a ref for the file input
  const fileInputRef = useRef(null);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getservices`)
      .then((response) => {
        if (response.status == 200) {
          setServicesdata(response.data.serviceslist)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  const mobilevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      setmobilevalidationerror("Please Enter A valid Mobile Number");
      setmobilevalid(false);
    } else {
      setmobilevalidationerror("");
      setmobilevalid(true);
    }
  };

  const phonevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      setphonevalidationerror("Please Enter A valid Mobile Number");
      setphonevalid(false);
    } else {
      setphonevalidationerror("");
      setphonevalid(true);
    }
  };














  const showAlert = (message) => {
    Swal.fire({
      title: "Thank You !",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };


  // toast message function
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  //this is uses for the pdf/image upload in database.

  const upload = (event) => {
    const imageFile = event.target.files[0];
    setResumefile(imageFile);
    if (!imageFile) {
      showToastMessage("Please select a file");
      return false;
    } else if (!imageFile.name.match(/\.(doc|docx|pdf|txt)$/)) {
      showToastMessage("Please select only .pdf, .txt, .doc");
      return false;
    }
  };


  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("phone", mobilenumber);
    bodyFormData.append("whatsapp", phonenumber);
    bodyFormData.append("description", values.description);
    bodyFormData.append("service_type", servicestype);
    bodyFormData.append("file", resumefile);
    bodyFormData.append("start_date", startDate);
    bodyFormData.append("country", country);
    const url = `${process.env.REACT_APP_BASE_URL}/requestprojectquotation`;
    axios
      .post(url, bodyFormData, {
        "Content-Type": "multipart/form-data",
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          showAlert(response.data.message)
          setmobilenumber("")
          setphonenumber("")
          setcountry("")
          setServicesType("")
          setResumefile(null)
          setStartDate("")
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
        }
        else if (response.data.status_code === 201) {
          showToastMessageone(response.data.message.name);
          showToastMessageone(response.data.message.email);
          showToastMessageone(response.data.message.whatsapp);
          showToastMessageone(response.data.message.phone);
          showToastMessageone(response.data.message.description);
          showToastMessageone(response.data.message.file);
          showToastMessageone(response.data.message.start_date);
          showToastMessageone(response.data.message.country);
          showToastMessageone(response.data.message.service_type);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      description: Yup.string()
        .required("Required!"),
    }),
    onSubmit: (values) => {
      setmobilenumber("")
      setphonenumber("")
      setcountry("")
      setServicesType("")
      setResumefile(null)
      setStartDate("")
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      handleSubmit(values);
      formik.resetForm();
    },
  });


  return (
    <div className="contactrequest-12">
      <Container>
        <div className="form-t11">
          <div className="form-t12">
            <FaEnvelopeOpenText />
          </div>
          <p>
            Share a few details about your project, and we will prepare a proposal for you within <strong>24</strong> hours.
          </p>
        </div>
        <div className="form-contact">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6}>
                <label className="Request_field">Enter Full Name</label>
                <Form.Group>
                  <Form.Control
                    placeholder="Enter Your Name"
                    type="text"
                    name="name"
                    autoComplete="off"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <p className="errormessage">{formik.errors.name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <label className="Request_field">
                  Enter Email Address
                </label>
                <Form.Group>
                  <Form.Control
                    placeholder="Enter Your Email"
                    type="email"
                    name="email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className="errormessage">{formik.errors.email}</p>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <label className="Request_field">
                  Enter Mobile Number
                </label>

                <PhoneInput
                  country={"in"}
                  value={mobilenumber}
                  onChange={(phone, value) => {
                    setmobilenumber(phone);
                    mobilevalidation(phone);
                  }}
                  //  defaultErrorMessage="Please Enter Valid Number"
                  prefix="+"
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: false,
                    style: { marginLeft: "0px", width: "100%" },
                  }}
                  isValid={(validmobilenumber) => {
                    if (
                      !new RegExp(/^[0-9+].{6,12}$/).test(validmobilenumber)
                    ) {
                      setvalid(false);
                      return false;
                    } else {
                      setvalid(true);
                      return true;
                    }
                  }}
                />
                <span>
                  {mobilevalid ? (
                    <></>
                  ) : (
                    <>
                      <p className="errormessage">{mobilevalidationerror}</p>
                    </>
                  )}
                </span>
              </Col>

              <Col md={6}>
                <label className="Request_field">
                  Enter Whatsaap Number
                </label>
                <PhoneInput
                  country={"in"}
                  value={phonenumber}
                  onChange={(phone, value) => {
                    setphonenumber(phone);
                    phonevalidation(phone);
                  }}
                  //  defaultErrorMessage="Please Enter Valid Number"
                  prefix="+"
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: false,
                    style: { marginLeft: "0px", width: "100%" },
                  }}
                  isValid={(validphonenumber) => {
                    if (
                      !new RegExp(/^[0-9+].{6,12}$/).test(validphonenumber)
                    ) {
                      setvalid(false);
                      return false;
                    } else {
                      setvalid(true);
                      return true;
                    }
                  }}
                />
                <span>
                  {phonevalid ? (
                    <></>
                  ) : (
                    <>
                      <p className="errormessage">{phonevalidationerror}</p>
                    </>
                  )}
                </span>
              </Col>

              <Col md={6}>
                <label className="Request_field">
                  Please Select Service/Project Type
                </label>
                <select
                  className="form-select"
                  name="servicestype"
                  value={servicestype}
                  onChange={(e) => setServicesType(e.target.value)}
                >
                  <option>Please Select Service</option>
                  {servicesdata.map((data, index) => {
                    return (
                      <option value={`${data.id}`} key={index}>
                        {data.title}
                      </option>
                    )
                  })}
                </select>
              </Col>

              <Col md={6} >
                <div className="start_date">
                  <label className="Request_field">
                    When you expected to start
                  </label>
                  <DatePicker
                    className="form-control"
                    selectsStart
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    startDate={startDate}
                    required={true}
                  />
                </div>
              </Col>

              <Col md={6}>
                <label className="Request_field">Please Select You Country</label>
                <select
                  className="form-select"
                  name="country"
                  onChange={(event) => setcountry(event.target.value)}
                  value={country}
                >
                  <option>Please Select Country</option>
                  <option value="India">
                    India
                  </option>
                  <option value="United States">
                    United States
                  </option>
                  <option value="Japan">
                    Japan
                  </option>
                  <option value="Oman">
                    Oman
                  </option>
                  <option value="Qatar">
                    Qatar
                  </option>
                  <option value="United Kingdom">
                    United Kingdom
                  </option>
                  <option value="Zimbabwe">
                    Zimbabwe
                  </option>
                  <option value="Italy">
                    Italy
                  </option>
                  <option value="Brazil">
                    Brazil
                  </option>
                  <option value="France">
                    France
                  </option>
                </select>
              </Col>
              <Col md={6}>
                <div className="field file-upload">
                  <label htmlFor="uploadfile" className="Request_field">
                    Upload Your Requirement
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="uploadfile"
                    ref={fileInputRef}
                    onChange={(e) => {
                      upload(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <label className="Request_field">Share some details related to project</label>
                <Form.Control
                  as="textarea"
                  style={{ height: "100px" }}
                  placeholder="Enter your description"
                  name="description"
                  className="form-control"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  type="message"
                  autoComplete="off"
                />
                {formik.errors.description && formik.touched.description && (
                  <p className="errormessage">{formik.errors.description}</p>
                )}
              </Col>
              <Col md={12}>
                <div className="contact-7 ">
                  <button
                    type="submit"
                    className="main-btn-resuest"
                  >
                    Submit your request
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <ToastContainer />
      </Container>
    </div>
  );
}

export default Rqform;
