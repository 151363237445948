
import React from "react";
import Routes from "./Routes"
import "./App.css";
import GoToTop from "../src/GoToTop";
// const App = () => {
function App() {
  return (
    <>
      <Routes />
      <GoToTop />
    </>
  );
};

export default App;