import React, { useState, useEffect } from "react";
import "./Form.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
// import { TextField } from '@mui/material';
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Swal from "sweetalert2";



function Form1() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const showAlert = () => {
    Swal.fire({
      title: "Message Sent",
      text: "Thank you for contacting us.We will get back to you asap.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
      //
      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),
      message: Yup.string().required("Required!"),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      showAlert();
      formik.resetForm();

      try {
        const response = await fetch(
          `https://impetrosys.com/new/api/Webservice/savecontacts`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (response.ok) {
          // Form data sent successfully
          // console.log("Form submitted successfully");
          showAlert();
          formik.resetForm(); // Reset form after successful submission
        } else {
          // Error in sending form data
          console.log("Error submitting form");
        }
      } catch (error) {
        console.log("Error submitting form", error);
      }
    },
  });

  return (

      <section id="contact" className="section active">
        {/* <Container>
          <div className="section_title mb-4 touct-text" data-aos="zoom-in">
            <h2>
              <span>Contact Us</span>
            </h2>
            <p className="section_subtitle mx-auto">How Can We Assist You Today?</p>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6} sm={6}>
                <lable className="form_contact_field">Name</lable>
                <Form.Group className="mb-3">
                  <Form.Control
                  className="form-control-1"
                    type="text"
                    name="name"
                    autoComplete="off"
                    placeholder="Enter your Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <p className="errormessage">{formik.errors.name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <lable className="form_contact_field">Email</lable>
                <Form.Group className="mb-3">
                  <Form.Control
                   className="form-control-1"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className="errormessage">{formik.errors.email}</p>
                  )}
                </Form.Group>
              </Col>

              <Col md={12}>
                <lable className="form_contact_field mt-2">Message</lable>
                <Form.Control
                 className="form-control-1"
                  as="textarea"
                  placeholder="Enter Your Message"
                  style={{ height: "125px" }}
                  type="message"
                  name="message"
                  autoComplete="off"
                  // multiline
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {formik.errors.message && formik.touched.message && (
                  <p className="errormessage">{formik.errors.message}</p>
                )}
              </Col>
            </Row>
            <div className="form-bt mt-4">
              <Button type="Submit" className="main-home">
                SEND
              </Button>
            </div>
          </Form>
        </Container> */}
      </section>
   
  );
}

export default Form1;
