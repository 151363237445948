import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
import Aboutus from "../Component/Abouts/Aboutus";
 import Counter from "../Component/Homes/Counter/Counter";
 function About() {
  return (
    <>
    <Header/>
    <Aboutus/>
     <Counter/>
      <Footer1 />
      <Footer />
    </>
  );
};

export default About;
